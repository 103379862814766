<template>
    <div class="data-component">
      <!-- You can add a loading indicator or data display here later -->
        <li v-for="region in regions" :key="region.id">
        {{ region.Name }}
        </li>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DataComponent',
    data() {
      return {
        // You might want to store your fetched data here
        regions: []
      };
    },
    mounted() {
      this.fetchRegions();
    },
    methods: {
      fetchRegions() {
        fetch('https://lotrwar.com/gameapi/regions')
          .then(response => {
            // Check if the request was successful
            if (!response.ok) {
              throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.json();
          })
          .then(data => {
            console.log(data);
            this.regions = data; // Store the data in your component's data
          })
          .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
          });
      }
    }
  }
  </script>
  
  <style scoped>
  /* Add component-specific styles here */
  </style>