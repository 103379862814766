<template>
  <div class="unity-game-container">
    <h1>Message from Unity: {{ unityMessage }}</h1>
    <h2>User: {{ userName }}</h2>
    <iframe :src="'/unityGame1/index.html?v=' + 0.03" width="1200" height="600"></iframe>
  </div>
</template>

<script>
export default {
  name: 'UnityGame',
  props: {
    userName: String
  },
  data() {
    return {
      unityMessage: ''
    };
  },
  methods: {
    sendDataToUnity(data) {
    const iframe = this.$el.querySelector('iframe');
    if (iframe && iframe.contentWindow) {
        // Correcting message sending to use Unity's SendMessage
        // Format: iframeElement.contentWindow.SendMessage("GameObjectName", "MethodName", "Argument");
        console.log(iframe.contentWindow);
        iframe.contentWindow.postMessage('MessageReceiver, ReceiveMessage,' + data, '*');
        //unityIframe.contentWindow.postMessage('MessageHandler,ReceiveMessage,' + userName, '*');

    } else {
        console.error('Unity iframe not found or not loaded');
    }
  },
    displayRandomText(message) {
      this.unityMessage = message;
      console.log('Message from Unity:', message); // Debugging line to see the output
    },
    receiveMessage(event) {
      if (event.data.from === 'Unity') {
        this.displayRandomText(event.data.message);
        console.log("sending data to unity");
      this.sendDataToUnity(this.userName);
      }
    }
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage);
  },
  beforeUnmount() {
    window.removeEventListener('message', this.receiveMessage);
  },
}
</script>
