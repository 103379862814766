<template>
  <login-component @update:user="updateUserName"></login-component>
  <data-component></data-component>
  <unity-game :user-name="userName"></unity-game>
  <button @click="forceReload">Reload Game</button>
</template>

<script>
import LoginComponent from './components/LoginComponent.vue'
import DataComponent from './components/DataComponent';
import UnityGame from './components/UnityGame';


export default {
  name: 'App',
  components: {
    LoginComponent,
    DataComponent,
    UnityGame
  },
  data() {
    return {
      userName: ''
    };
  },
  methods: {
    updateUserName(name) {
      this.userName = name;
    },
    forceReload() {
      window.location.reload(true); // This is supposed to force reload from the server
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
