<template>
  <div>
    <!-- Show welcome message and sign-out button if user is signed in -->
    <div v-if="isSignedIn || dev">
      Welcome, {{ userName }}
      <button v-if="dev == false" class="signout-button" @click="signOut">Sign out</button>
    </div>

    <!-- Show login button if user is not signed in -->
    <div v-else class="google-signin-button" @click="handleAuthClick">
      Sign in with Google
    </div>
  </div>
</template>

<script>
import { gapi } from 'gapi-script';

export default {
  name: 'GoogleLogin',
  data() {
    return {
      dev: false,
      isSignedIn: false,  // Tracks sign-in status
      userName: ''        // User's name
    };
  },
  methods: {
    handleClientLoad() {
      gapi.load('client:auth2', this.initClient);
    },
    initClient() {
      gapi.client.init({
        clientId: '609655963751-kd41o7ndnprp8put92irton8bnmqb7cl.apps.googleusercontent.com',
        scope: 'email'
      }).then(() => {

        if (this.dev) {
          this.userName = "Ziggy";
          this.isSignedIn = true;
        }
        else {
          this.authInstance = gapi.auth2.getAuthInstance();
          this.updateSigninStatus(this.authInstance.isSignedIn.get());
          this.authInstance.isSignedIn.listen(this.updateSigninStatus);
        }

        console.warn("EMMITED " + this.userName);
        this.$emit('update:user', this.userName);  // Emit an event with the userName
      });
    },
    handleAuthClick() {
      this.authInstance.signIn();
    },
    updateSigninStatus(isSignedIn) {
      this.isSignedIn = isSignedIn;
      if (isSignedIn) {
        this.userName = this.authInstance.currentUser.get().getBasicProfile().getName();
        console.log("GETTING EMAIL");
        this.userEmail = this.getEmailFromGoogle(); // Method to retrieve email from Google
        this.checkUserId(this.userEmail);
      } else {
        this.userName = '';
      }
    },
    signOut() {
      this.authInstance.signOut().then(() => {
        this.updateSigninStatus(false); // Explicitly set to false since the user is now signed out
      }).catch(error => {
        console.error('Sign out error', error);
      });
    },
    checkUserId(email) {
      console.log("checking user id");
      fetch(`/gameapi/users/check-id?email=${encodeURIComponent(email)}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          this.userId = data.id; // Set the user ID from the response
          console.log("User ID found:", this.userId);
        })
        .catch(error => {
          if (error.message === 'Network response was not ok') {
            console.log("No user ID found. Set it.");
            this.createUserId(this.userName);
          } else {
            console.error('There was a problem with the fetch operation:', error);
          }
        });
    },
    createUserId(userId) {
      const email = this.userEmail; // This should be retrieved after user login via Google
      const postData = { userId, email };
      console.log("trying to create user ID")

      fetch('/gameapi/users/set-id', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
      })
        .then(response => {
          console.log(response);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(() => {
          this.userId = userId;
          alert('ID created successfully!');
        })
        .catch(error => {
          if (error.message.includes('409')) {
            alert('This ID is already taken, please choose another one.');
          } else {
            console.error('Error creating user ID', error);
          }
        });
    },
    getEmailFromGoogle() {
      if (!this.authInstance) {
        console.error("Google API not initialized or user not signed in.");
        return '';
      }

      let user = this.authInstance.currentUser.get();
      if (user) {
        let profile = user.getBasicProfile();
        if (profile) {
          return profile.getEmail(); // Returns the email address of the signed-in user
        } else {
          console.error("No user profile available.");
          return '';
        }
      } else {
        console.error("No current user available.");
        return '';
      }
    },
  },
  mounted() {
    this.handleClientLoad();
  }
}
</script>

<style>
.signout-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.google-signin-button {
  padding: 5px 10px;
  background-color: #4285F4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>